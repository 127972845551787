html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}


/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

svg {
  width: 100%;
  height: 100%;
}

b, strong {
	font-weight: 900;
}

h1, h2 {font-weight: 900; letter-spacing: 0.5px; text-align: center;}
h2 {font-size: 18px; line-height: 24px; text-transform: uppercase;}
a {color:#58262A; text-decoration: none;}
body, input, textarea {color:#58262A; font-family: 'M PLUS Rounded 1c', sans-serif;}
body.creador, .creador #content {background:#FF3D92;}
body.receptor, .receptor #content {background: #FFEF70;}

.disabled {
	filter: grayscale(1);
	opacity: 0.65;
}

/* Bases y landing*/
.creador #header {background: #FFEF70;}
.creador .ondas_home {background-image: url(img/home_1.svg); background-position: center;}
.creador h2 {color:#FFF;}

.receptor #header {background:#FF3D92;}
.receptor .ondas_home {background-image: url(img/home_2.svg); background-position: center;}
.receptor #header h1 {color:#FFF;}
.receptor .nav {z-index: 999;}

#header, #content {padding: 3rem; padding-bottom: 2rem; text-align: center;}
#content {padding-top:15px;}
#header h1, .ondas_home h2 {max-width: 500px; margin:0 auto;}
#header h1 {padding-top:30px; font-size: 25px; line-height: 32px;}
.ondas_home h2 {max-width: 250px; bottom: 15px; position: absolute; left: 50%; transform: translateX(-50%);}

.ondas_home {background-size: 1920px 137px; width: 100%; height: 137px; position: relative;}
.logo {text-align: center;}
.logo img {max-width: 350px;}

/* Botones redes*/
.button {min-width:200px; font-size: 18px; line-height: 24px; font-weight: 900; padding: 8px 0 10px; border-radius: 100px; margin:5px 10px 5px 10px; display: inline-block;cursor: pointer;}
.button svg {padding-right:5px; font-size:20px;}
.button.apple .svg-inline--fa {vertical-align: -0.057em;}
.creador .button {background-color: #FFEF70;}
.receptor .button {background-color: #FF3D92; color:#FFF;}
.button:hover {background-color: #44D86D !important; color:#FFF !important;}
.button > * {
	pointer-events: none;
}


.fromto {padding: 60px 30px; line-height: 24px; font-size:18px; letter-spacing: 0.5px; font-weight: 400;}
.fromto .name {font-weight: 900;}

.logo-receptor {position: fixed; right: 30px; top: 60px; z-index:99;}
.logo-receptor img {width: 150px;}

/* Botones plataforma*/
.button.download, .button.next, .button.back {padding: 10px 30px 10px 30px; text-transform: uppercase; background-color: #5DE8DE; color:#58262A; margin-bottom:60px;}
.button.next, .button.back {position: absolute; min-width: auto;}
.button.next {right:30px;}
.button.back {left:30px;}

/*Share receptor*/
.botones h2 {margin-bottom:30px;}
.nav {position:absolute; width: 100%; display:block; bottom:110px;}
.receptor.share .nav, .creador.share .nav {position: relative; text-align: center; bottom: auto; margin-top: 30px;}
.receptor.share .nav .button.next, .creador.share .nav .button.next {position: relative; right: auto; min-width: 160px;}
.receptor.share .nav .button.back, .creador.share .nav .button.back {position: relative; left: auto;}
.button.new {margin-bottom:0px;}
.imagen_regalo img {max-width:250px; border-radius: 10px; border: 4px solid #FFF;}
.imagen_regalo {margin-bottom:30px;}

/*Redes*/
.new {margin-top:60px;}
.new h2 {margin-bottom:30px;}

/* Legal*/
.legal {max-width: 1200px; margin:0 auto; padding-top:40px;}
.legal p {font-size:13px; line-height: 1.5; padding-top: 10px; padding-bottom: 10px;}
.creador .legal p, .creador .legal a {color:#FFF;}
.receptor .legal p, .receptor .legal a {color:#58262A;}
.legal a:hover {text-decoration: underline;}


/* Formularios */

	.contenedor {z-index:99; position: relative; width: 100%;}
	.contenedor .formulario {float:left; background-color: #FFF; display: inline-block; width: 35%; height:100%;position: relative;z-index:1;}
	.contenedor .formulario .form_content {padding: 60px 30px; position: relative; height: calc(100% - 120px);}
	.contenedor .formulario .form_content .nav .button.next {right:50px; margin-bottom:0px;}
	.contenedor .formulario .form_content .nav .button.back {left:-10px;}
	.contenedor .resultado {float:right; width: 65%; height: 100vh; display: inline-block;}
	.contenedor .formulario h1 {font-size: 25px; line-height: 32px; text-align: left; margin-bottom:15px;}

	.contenedor .formulario ul li {display: inline-block; margin-right:15px;}
	.contenedor .formulario ul li img {width: 50px;}

	.contenedor .formulario ul li:last-of-type {margin-right:0px;}

	input, textarea {width: calc(100% - 20px); color:#58262A; font-weight: 400; font-size: 18px; line-height: 24px; border: 0px; background-color: #F1F1F1; border-radius: 5px; padding:5px 10px;}
	input {margin-bottom:30px;}
	textarea::placeholder {color:#ADADAD;}
	label {font-weight: 900; font-size: 18px; line-height: 24px; margin-bottom:-10px; display: block;}
	.create-new {margin-top:0px;}

/* Fondos caramelos */
	/*OSO*/
	body.oso, body.paleta, body.caramelo {
	  height: 100vh;
	  overflow: hidden;
	  position: relative;
	}

	.stripe, .stripe_paleta, .stripe_caramelo {
	  position: relative;
	  display: flex;
	  z-index:1;
	  width: 100%;
	  height: 100%;}

	  .lines::before {
	    content: "";
	    position: absolute;
	    top: 0;
	    left: 0;
	    width: calc(100% + 424.26px);
	    height: 100%;
		background-image: linear-gradient(135deg, #ffef70 16.67%, #5de8de 16.67%, #5de8de 33.33%, #ff3d92 33.33%, #ff3d92 50%, #ffef70 50%, #ffef70 66.67%, #5de8de 66.67%, #5de8de 83.33%, #ff3d92 83.33%, #ff3d92 100%);
		background-size: 424.26px 424.26px;
	    animation: stripeTransform 10s linear infinite;
	  }

	  .lines::after {
	    content: "";
	    position: absolute;
	    width: 100%;
	    height: 100%;
	  }

	/*Paleta*/

	  .ondas::before {
	    content: "";
	    position: absolute;
	    top: 0;
	    left: 0;
	    width: 100%;
	    height: calc(100% + 854px);
		background-image: url(img/fondo_curvas.svg); background-position: center;
		background-size: 400px 854px;
		background-repeat: repeat;
	    animation: stripePaletaTransform 20s linear infinite;
	  }

	  .ondas::after {
	    content: "";
	    position: absolute;
	    width: 100%;
	    height: 100%;
	  }


	/*Caramelo*/
	body.caramelo {
	  background:#5DE8DE;
	}

	.helice {
		background-image: url(img/helice.svg); 
		background-position: center;
		background-repeat: no-repeat;
		margin: auto;
		position: absolute;
		left:0;
		right: 0;
		top: 0;
		bottom: 0;
    	height: 300%;
	   animation: rotating 60s linear infinite;
	}

	.creador-helice .helice {
		left: auto;
		right: -16%;
		width: 100%;
	}

@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    transform-origin: center;
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
    
  }
}

	@keyframes stripeTransform {
	  0% {
	    transform: translateX(0);
	  }
	  100% {
	    transform: translateX(calc(424.26px * -1));
	  }
	}

	@keyframes stripePaletaTransform {
	  100% {
	    transform: translateY(0);
	  }
	  0% {
	    transform: translateY(calc(854px * -1));
	  }
	}

	.oso .resultado canvas {
		display: block;
		position: absolute;
		top: 40%;
		left: 50%;
		width: 60%;
		transform: translate(-38.1121%, -33.625%);
	}
	
	.paleta .resultado canvas {
		display: block;
		position: absolute;
		top: 30%;
		left: 50%;
		width: 50%;
		transform: translate(-63.1478%, -21.5696%);
	}
	
	.caramelo .resultado canvas {
		width: 40%;
		transform: translate(-50.8075%, -20.9505%);
	}

/* Responsive */

@media (max-width : 1300px) {
	.paleta .resultado canvas {width: 60%; top: 40%; left:55%;}
	.nav {position: relative; bottom:auto; display:inline-block;}
	.receptor.figura .nav {position: absolute; bottom: 0px;}
	.receptor.figura .nav .button.next {float: right;}
	.button.next, .button.back {position: relative; display: inline-block;}
	.contenedor .formulario .form_content .nav .button.next {right:auto; float: right;}
	.contenedor .formulario .form_content .nav .button.back, .contenedor .formulario .form_content .nav .button.next {margin-bottom:0px;}
	.button.next, .button.back {margin-bottom:30px;}
	.button.download {margin-bottom:0px;}
	.receptor.share .button.download {margin-bottom:60px;}
	.receptor.share .button.download.new {margin-bottom:0px;}
	.creador .nav, .receptor.share .nav {text-align: center; margin-top:30px;}
	.creador .nav .button.next, .receptor.share .nav .button.next, .button.download {right: auto; min-width: 160px;}
	.imagen_regalo img {max-width: 220px;}
	.button.back {left: auto;}
	.contenedor .formulario, .contenedor .resultado {float:none; display: block; width: 100%;}
	.contenedor .resultado {min-height: 85%;height:auto}
	.receptor .stripe, .receptor .stripe, .receptor .stripe_paleta, .receptor .stripe_caramelo {height: 100vh;}
	.stripe, .stripe, .stripe_paleta, .stripe_caramelo {height: 70vh;}
	.contenedor .formulario .form_content .nav {margin-bottom:0px; margin-top:30px;}
	.contenedor .formulario {height: auto;}
	body.oso, body.paleta, body.caramelo {overflow-y: visible; height: auto;}
	.no-overflow {overflow-y: hidden !important; max-height: 100vh;}
	.no-overflow .contenedor .formulario {min-height: 57.8%;}

	.creador-helice .helice {
	    left: -50%;
	    top: -40%;
	    right: 0;
	    width: 200%;
	}

	.helice_container, .ondas_container, .lines_container {position: absolute; height: 100vh; width:100%; overflow:hidden; top:0px;}
	.contenedor .formulario .form_content .nav .button.back {float: left;}
	.contenedor .formulario .form_content .nav .button.next {margin-right:0px;}

	input[type="text"],
	select:focus,
	textarea {
	  font-size: 16px;
	}

	.helice_container {
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		transform: none;
		width: auto;
		height: auto;
	}
}

@media (max-width : 1024px) {
	.contenedor .formulario .form_content {padding:30px;}
	input {margin-bottom:15px;}
}

@media (max-width : 768px) {
	.caramelo .resultado canvas {width: 60%;}
}

@media (max-width : 599px) {
	.oso .resultado canvas {width: 120%; top: 40%; left:45%;}
	.paleta .resultado canvas {width: 80%; top:30%; left:60%;}
	.caramelo .resultado canvas {width: 85%; top:45%;}
	#header, #content {padding: 30px;}
	.logo img {max-width: 250px;}
	#header h1, .contenedor .formulario h1 {font-size:20px; line-height: 26px;}
	.ondas_home h2, .receptor.share h2, .button, label, input, textarea {font-size:16px; line-height: 22px;}
	.button svg {font-size:18px;}
	.legal p {font-size:12px;}
	.helice {width: 200%; height: 300%; left:-50%;}
	.receptor.figura .nav {position:fixed; bottom: 30px;} 

	.fromto {padding: 30px 30px; line-height: 22px; font-size:16px;}
	.logo-receptor {top: 30px;}
	.logo-receptor img {width: 120px;}
}

@media (max-width : 390px) {
	#header h1, .contenedor .formulario h1 {font-size:18px; line-height: 24px;}
	.ondas_home h2, .button, label, input, textarea {font-size:16px; line-height: 22px;}
	.button.download, .button.next, .button.back {padding: 10px 20px 10px 20px; margin:0px;}
	.imagen_regalo img {max-width: 200px;}
	.contenedor .formulario .form_content .nav .button.back {left:0px;}
	.creador .nav {margin-bottom:30px;}
	.logo-receptor img {width: 100px;}
	.receptor.share .nav .button.next, .receptor.share .nav .button.back {margin-bottom:30px;}

	.formulario {margin-top:-30px;}
	.elegir .formulario {padding-bottom:30px;}
}


@media (max-width : 360px) {
	.oso .resultado canvas {width: 110%;}
	.paleta .resultado canvas {width: 85%; top:35%;}
	.contenedor .formulario .form_content {padding:30px 15px;}
	.creador .nav {margin-bottom:30px;}
	.fromto {padding: 30px 15px; line-height: 20px; font-size:14px;}
	.logo-receptor {top: 20px; right:15px;}
	.receptor.figura .nav {bottom: 30px;}
	.receptor.figura .button.next {right:15px;}
	.receptor.share .nav .button.next, .receptor.share .nav .button.back {margin-bottom:30px;}
}

textarea {
	resize: none;
}

.contenedor .formulario ul li img {width: 80px;}

@media (max-width : 599px) {
.contenedor .formulario ul li img {width: 60px;}
}

@media (max-width : 390px) {
.contenedor .formulario ul li img {width: 50px;}
}

.resultado {
	position: relative;
	overflow: hidden;
	opacity: 0;
}

@keyframes ready {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

.resultado.ready,
.card_preview.ready {
	animation: ready 1s forwards;
}

.resultado canvas {
	display: block;
	position: absolute;
	top: 50%;
	left: 50%;
	width: 100%;
}

.card_preview {
	position: fixed;
	overflow: hidden;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	opacity: 0;
}

.card_preview canvas {
	display: block;
	position: absolute;
	top: 50%;
	left: 50%;
}

.oso .card_preview canvas {
	top: 40%;
	height: 72%;
	transform: translate(-38.1121%, -33.625%);
}

.paleta .card_preview canvas {
	height: 92%;
	top: 34%;
	left: 70%;
	transform: translate(-63.1478%, -21.5696%);
}

.caramelo .card_preview canvas {
	height: 100%;
	transform: translate(-50.8075%, -20.9505%);
}

@media (orientation: landscape) {
	.oso .card_preview canvas {
		height: 90%;
		top: 5%;
		left: 50%;
		transform: translate(-38.1121%, 0);
	}

	.paleta .card_preview canvas {
		height: 90%;
		top: 5%;
		left: 50%;
		transform: translate(-63.1478%, 0);
	}

	.caramelo .card_preview canvas {
		height: 140%;
		top: 50%;
		left: 50%;
	}
}

@media screen and (max-width: 700px) {
  .buttons {
    word-spacing: 100vw;
  }
  .legal {
    font-size: 10px;
  }
}

.slide {
  max-height: 0;
  opacity: 0;
  overflow: hidden;
  transition: all 250ms ease-in-out;
}

.slide--open {
  max-height: 10em;
  opacity: 1;
}

.form {
	display: block;
	margin-top: 24px;
}

.creador .form {
	color: #ffffff;
}

.form > * + * {
  margin-top: 1em;
}

.form label {
	font-weight: 700;
	margin-bottom: 16px;
}

.form__input {
  display: flex;
  max-width: 450px;
  align-items: stretch;
  margin: 0 auto;
}

.form__input input {
  display: block;
  flex: 1;
	line-height: 24px;
	margin: 0;
	padding: 9px 17px;
	border-radius: 21px 0 0 21px;
	outline: 0;
}

.form__input button.button {
  display: block;
	flex-shrink: 0;
	min-width: 0;
	padding: 0 1em;
	border: none;
	margin: 0;
	border-radius: 0 21px 21px 0;
	outline: 0;
}

@keyframes blow {
	from {
		opacity: 1;
		transform: translateY(-100%);
	}
	to {
		opacity: 0;
		transform: translateY(-200%);
	}
}

.copied {
	position: fixed;
	transform: translate(-50%, -100%);
	background-color: #ffffff;
	font-size: 1em;
	font-weight: 900;
	color: #58262A;
	padding: 0 1em;
	line-height: 2;
	border-radius: 1em;
	opacity: 0;
	pointer-events: none;
	white-space: nowrap;
}

.copied.done {
	animation: blow 1s forwards;
}

.fromto {text-shadow: 1px 1px #FFF;}

.play {
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 16vmin;
	height: 16vmin;
	border-radius: 8vmin;
	font-size: 6.5vmin;
	color: #ffffff;
	background-color: #58262A;
	cursor: pointer;
	z-index: 999999;
}

.play svg {
	transform: translateX(6%);
}
